/* Test borders for structure */
/* .navlinks, p, a, .logo, div, section{
  border:1px dotted black;
} */

/* Entire App */
.App {
  text-align: center;
  padding-top: 6%;
  overflow-x: hidden;
  background-image: linear-gradient(
    to right,
    rgb(167, 194, 240),
    white,
    rgb(167, 194, 240)
  );
}
section {
  margin-top: 5%;
}
/* NAV FIX */
.nav-fix {
  margin-top: 0%;
  padding: 5%;
}
/* Navigation Section */
.navlinks-container {
  z-index: 1;
  display: flex;
  justify-content: center;
  background: -webkit-linear-gradient(rgb(17, 7, 68), rgb(167, 194, 240));
  overflow: hidden;
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
}
.navlinks {
  padding: 0.5% 1%;
}
.navlinks a {
  color: white;
  font-size: 20px;
  text-decoration: none;
  padding: 30px;
}
.active {
  background: -webkit-linear-gradient(
    rgb(255, 255, 255),
    rgb(78, 60, 184),
    rgb(27, 28, 31)
  );
}
.navlinks a:hover {
  background: -webkit-linear-gradient(
    rgb(255, 255, 255),
    rgb(78, 60, 184),
    rgb(27, 28, 31)
  );
}

/* About Section */
section div h1 {
  font-size: 2rem;
}
.photo {
  max-width: 50%;
  border-radius: 50%;
  box-shadow: -3px -3px 10px black;
}
#About {
  background: -webkit-linear-gradient(
    rgb(17, 7, 68),
    rgb(56, 88, 143),
    rgb(17, 7, 68)
  );
  padding: 2%;
  display: flex;
  justify-content: center;
  width: 60%;
  margin: 3% auto;
  box-shadow: -5px -5px 15px black;
  border-radius: 10px;
}
.mydetails {
  width: 100%;
  padding: 5%;
  color: white;
}
.mydetails a {
  color: blue;
  background-color: white;
  margin: 0% 2%;
  padding: 1% 2%;
  box-shadow: -2px -2px 5px black;
  border-radius: 5px;
}

.mydetails a:hover {
  color: white;
  background: blue;
  box-shadow: -2px -2px 5px black;
  transition: 1s;
}

.mydetails p {
  font-size: 18px;
}

/* Skills Section */
#Skills {
  padding-top: 3%;
}

.icons-container {
  display: flex;
  justify-content: center;
}

#Skills .fab {
  font-size: 8rem;
}
#Skills h2 {
  padding: 0%;
  font-size: 3rem;
  background: -webkit-linear-gradient(
    rgb(255, 255, 255),
    rgb(78, 60, 184),
    rgb(27, 28, 31)
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.icondiv {
  margin: 2%;
  min-width: 10%;
}
.icondiv p {
  color: rgb(71, 71, 71);
  font-family: Verdana;
  font-style: italic;
  box-shadow: 5px 5px 15px rgb(149, 140, 192);
  border-radius: 5px;
}
.icondiv:hover {
  transform: rotate(360deg);
  transition: transform 0.5s;
}
.fa-html5 {
  color: orangered;
}
.fa-css3-alt {
  color: rgb(84, 106, 231);
}
.fa-js-square {
  color: yellow;
}
.fa-react {
  color: rgb(88, 164, 211);
}
.fa-sass {
  color: rgb(219, 108, 195);
}
.fa-python {
  color: rgb(82, 172, 79);
}
.fa-node-js {
  color: rgb(13, 173, 13);
}
.fa-bootstrap {
  color: rgb(110, 8, 110);
}
.fa-less {
  color: rgb(0, 99, 197);
}

/* Projects Section*/

section .nav-fix h2 {
  margin: 0%;
  padding: 0%;
  margin-bottom: -100px;
  font-size: 3rem;
  background: -webkit-linear-gradient(
    rgb(255, 255, 255),
    rgb(78, 60, 184),
    rgb(27, 28, 31)
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.projects-container {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  margin: 0 auto;
  margin-top: 50px;
  
}

.single-project {
  padding: 25px;
  border: 2px solid gray;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  border-radius: 10px;
  margin: 0 auto;
  margin-bottom: 10%;
  width: 25%;
  overflow: auto
}

.single-project h2 {
  font-size: 2rem;
  background: -webkit-linear-gradient(
    rgb(255, 255, 255),
    rgb(78, 60, 184),
    rgb(27, 28, 31)
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  flex-grow: 1;
}

.single-project p {
  text-align: left;
  width: 90%;
  margin: 5% auto;
}

.single-project img {
  width: 85%;
  height: 50%;
  border-radius: 5%;
}
.single-project img:hover {
  opacity:.5;
  transition: all 0.5s ease;
}

.projfab:hover {
  transform: scale(2);
  transition: all 0.5s ease;
}

@media only screen and (max-width: 1200px) {
  .icons-container {
    flex-direction: column;
    align-items: center;
  }
  .icondiv {
    width: 100%;
  }
  .projects-container {
    padding-top: 10px;
    flex-direction: column;
  }
  .single-project {
    width: 80%;
  }
  #About {
    flex-direction: column;
    width: 80%;
  }
  .mydetails {
    min-width: 80%;
  }
  .mydetails,
  .photo {
    margin: 0 auto;
  }
  .single-project p {
    text-align: center;
  }
  .mobile-icons-container {
    display: flex;
    justify-content: space-around;
    align-content: center;
  }
}
